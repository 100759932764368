import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { graphql, Link } from "gatsby";
import Layout from '../../../../components/layout';
import SEO from '../../../../components/seo';
import { IntroProject, Intro, IntroImage } from '../../../../components/Intro/Intro';
import { Section, Container, CSSGrid, Col } from '../../../../components/Grid';
import { MainProject, CardStaticImg, CardLink, CardContainer, CardBody } from '../../../../components/Card';
import { ButtonGhost } from '../../../../components/Button';
import { designTokens } from '../../../../components/Theme/designTokens';
import projects from '../../../../data/projects';
import { IconBox, IconCard } from '../../../../components/IconBox';
import { Box } from '../../../../components/Box';
import { Text } from '../../../../components/Text';
import { OutboundLink, StyledLink } from '../../../../components/StyledLink';
import { CallToAction } from '../../../../components/CallToAction';
import MdPerson from 'react-ionicons/lib/MdPerson';
import MdFlag from 'react-ionicons/lib/MdFlag';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Advisar" mdxType="SEO" />
    <IntroProject projectName="Advisar" projectDescription="An eCommerce management platform for contractors and internal team members" projectCaption="Providing contractors a better sales enablement and quoting tool for our products" projectYear="2019 - Present" projectImage="advisar-preview" subProject="Masonite" subProjectLink="masonite" mdxType="IntroProject" />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Background 📋</h5>
        <p>Before we dive into the product, we should probably understand the goals of Advisar, a child company of it’s parent, Masonite - a global builder of doors.</p>
        <p>Masonite is in the business of manufacturing and selling high-volumes of doors to distributors, such as The Home Depot and Lowe’s.</p>
        <p>But in 2017, Masonite wanted to begin testing products downchannel to contractors and homeowners - so they formed a small internal incubator that would serve as “beta test” with a single goal in mind:</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <CSSGrid mdxType="CSSGrid">
  <Col mdxType="Col">
    <Box center bg={({
          theme
        }) => theme.greenTransparent} marginBottom={designTokens.space[6]} paddingY={designTokens.space[6]} mdxType="Box">
      <Container mdxType="Container">
        <CSSGrid mdxType="CSSGrid">
          <Col mdxType="Col">
            <Text center mdxType="Text">Objective:</Text>
            <p className="lead">Help contractors connect with homeowners, enable their sales teams, manage their orders, and gain insights into their business</p>
          </Col>
        </CSSGrid>
      </Container>
    </Box>
  </Col>
    </CSSGrid>
    <Container mdxType="Container">
  <CSSGrid mdxType="CSSGrid">
    <Col col={1 / 8} media={{
          phone: 1
        }} mdxType="Col"></Col>
    <Col col={6 / 8} media={{
          phone: 1
        }} mdxType="Col">
      <p>After many small product pivots, Advisar began seeing real traction with medium-to-large size home remodelers. <strong>Using qualitative research, market data, and iterative design methods, an application was created to help contractors configure, quote, order and track their purchases.</strong></p>
    </Col>
    <Col col={1 / 8} media={{
          phone: 1
        }} mdxType="Col"></Col>
  </CSSGrid>
    </Container>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <IconCard cardTitle="My Role" cardText="I joined the Advisar team in early 2019 as the sole designer on a development team made up of 8 engineers, a development manager, 2 QA engineers, and a product owner." mdxType="IconCard">
          <MdPerson fontSize={designTokens.space[5]} color={designTokens.colors.base.grey0} mdxType="MdPerson" />
        </IconCard>
        <p>While our team was also working through other projects, including a <StyledLink to={'./work/masonite/door-configurator'} underline="true" mdxType="StyledLink">redesign of our door configurator</StyledLink>, we saw a need to rectify a few design issues in the Advisar Core Application as well - consolidating the UI for both under a single design system.</p>
        <p>We saw incredible value using a research-backed design process from our experience in the configurator, so we began doing the same, <i>in tandem,</i> with this project (<OutboundLink rel="noopener noreferrer" underline="true" target="_blank" href="https://masonite-design-process.netlify.app/" mdxType="OutboundLink">Take a look at the design process we used!</OutboundLink>).</p>
        <p>As the only designer on the team, and juggling many projects running in Agile/SCRUM, staying organized using a design process was crucial for me - managing research, user interviews, prototyping, and wireframing could all be compartmentalized into phases.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Let's take a quick glance at the UI before implementing a design system and what design changes were made based on research</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "135.83333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAbCAYAAAB836/YAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE7ElEQVRIx5VVa29UVRSd3+sHDChMKS1tZ9pOW9umGD5oxaghQZSH4SUfLBVpMUgqJBZsKULsdF73MXPv3Pd7ufaZacFgjJ5k5Zy7zzn77L3245ZyvYvk9Z/I2joKswcEMREN5vB/wI9430Ip00yEWgdpGCIwumhevgrt6g20L19D+9KV/47rtxA32iilojAIkQPIwghrswu4fOIUrnw4gq/fP0F8gEvHTij8bf2W7OJ7x3B/6RzSloZS0jFgdbuIIrrJce37O6guLWLu4xVUFhcxVpvBxPwcJufnj9ZnuR4nRDa5MI+RyhRu3L0L2K5YaMBzHMRxjKIokKcZbNuCYWgoshxmV6MHAQrKza6OhA+7XgCTl5FliOhdTLryJEFCb5WFSiEFMjY3N1GtVLG8vIxarYbKVAWVSgXVahWrq6tYWVnB8tISarOzmKJ8enoap0dH8WTrV8DxBwod20ZAK2Q4rotGo4FmswldN9BhwDRNQ6fTgWmaqNfr2N/fH+x1Bnttzi6NyhhUpdC2LDgiyHOlVJTbfOSfRkj3+v3+O/KCd0WXShvh4FCZXNB1Ha1WSyn1PO8I8qgoE7nMLr05REQjUuZ0KdNNZEmKnAGRsb6+juPHj2NsbAwjIyMol8tHENn4+CHGFaYmp3Dy1ElyuDXgUCmMExVhGRo5e/zLI2zxwPb2Np4RMj9//hyvXr3Cixd72NnZJXYUdnd38Yx7XcMYVIqYmTH8xZCL+xsPcWaiijnm2AwjOctIy1ybm8OFC5/h/OoX+OSrbzFD+fTMtNo/Q0ufPnk6sDBnHkZ2HwH5yQMPT7b3cevHXXi9Lnrk0hrCpgV6swWj3YbbsygzFOzh7Bj0VILiWy70tsnLPViGhZhFnoXJvyN4V5bwXmh7KHXCDAd80WMlWOww6w8f4876Jm7fe4Nbaw+OcHtNvjcUbgvubeDmDw+w9vMWml6Mkh6mrONQ8RezrBbOXUD57DzKZ2ZxiiiPkaOpReIjjHEuT9QwOrmA0YkFnOa50zITy+c/R71HCzUqdD1f1bKMPTfHHx3Wcq8Psx/A8uhKBoWYqdr0erB51qY1OunquWx7KdthUkDjghbSVYfJ6ftI84JVEh51HlUBTCfJAkEuyc/vlPT4fkD4CFgI0jx8tr4jhUXo8WqqFIgyqQo5LErEcqmeiHCoZF+3kLALedx3hl0qS1M27QQdUdgOMtyse/imHuF6k66w/Xuuo8pJLEp5WLhNOLtmF+1nO0hYWb48LOWWDUrWTzJ0/ASlFrVefNHFpy89fLkfo9GnJaTA8clNlPBSNADXPhVGe3u0nkHMSYHvgA0SnqkpjzR6q6KMkM2yGATFINmOR15IssXM7xg9uEwnn+eEfK+AemhDj3HxtYvvDgKs/m7iqe6jF9FlIVJnHvoMRpgxUm6EPhX1qdR2A3TZmTX+DWX2mMA29zwm8W/dCPfbIR7pIX7SYry0IphhOkgbUcaGhiQfKnR9ZV2v7ynrTMtRCoUGvWurvTwld4y2/BrkVxBGw6AwykU2aDWcURhMKD+MiygtClqkEPIlPxI5wY1AILJ4AFm7lNHboiREuvKjYfhd5lKj5yrL5CfUZifSmeD1Zhstra1kYqFG2KTEIiWH6ArfysIoL5pOUDT7ftHoB8WBzZk4sD0FtbberJs8I3Oj/xaGctH1FwuJltMYMyLJAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Before and After",
          "title": "Before and After",
          "src": "/static/59ffeea49af6af9225e26d9e021682dd/d9199/ca-before-after.png",
          "srcSet": ["/static/59ffeea49af6af9225e26d9e021682dd/8ff5a/ca-before-after.png 240w", "/static/59ffeea49af6af9225e26d9e021682dd/e85cb/ca-before-after.png 480w", "/static/59ffeea49af6af9225e26d9e021682dd/d9199/ca-before-after.png 960w", "/static/59ffeea49af6af9225e26d9e021682dd/07a9c/ca-before-after.png 1440w", "/static/59ffeea49af6af9225e26d9e021682dd/29114/ca-before-after.png 1920w", "/static/59ffeea49af6af9225e26d9e021682dd/efe17/ca-before-after.png 2860w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Discovery and Research 🕵️‍♀️</h5>
        <p>We had alot to do and we needed to begin prioritizing which features to add/edit in the core application.</p>
        <p>Before any of that, we had to conduct some qualititave research using user interviews from our contractor clients. Using this data, we were able to see exactly what their current process looks like, their pain points with the current interface, and how we could prioritize a rollout of new features that would help allay their frustrations.</p>
        <h6>Pain Points</h6>
        <Box bg={({
              theme
            }) => theme.pinkTransparent} marginBottom={designTokens.space[3]} mdxType="Box">
          <Text bold marginBottom={designTokens.space[2]} size={designTokens.fontSizes[1]} mdxType="Text">Quotes/Orders are difficult to manage</Text>
          <Text size={designTokens.fontSizes[1]} mdxType="Text">Contractors were having trouble moving through the sale of a remodel seamlessly - from configuring a product, to lead, to quote, to order.</Text>
        </Box>
        <Box bg={({
              theme
            }) => theme.pinkTransparent} marginBottom={designTokens.space[3]} mdxType="Box">
          <Text bold marginBottom={designTokens.space[2]} size={designTokens.fontSizes[1]} mdxType="Text">UI is inconsistent</Text>
          <Text size={designTokens.fontSizes[1]} mdxType="Text">The style of the configurator interface differs greatly from that of the Core application for contractors.</Text>
        </Box>
        <Box bg={({
              theme
            }) => theme.pinkTransparent} marginBottom={designTokens.space[3]} mdxType="Box">
          <Text bold marginBottom={designTokens.space[2]} size={designTokens.fontSizes[1]} mdxType="Text">Unable to see the health of the business</Text>
          <Text size={designTokens.fontSizes[1]} mdxType="Text">Even though contractors could see sales coming and going through the application, they were having trouble indexing on the insights that could be made from their data.</Text>
        </Box>
        <p>We began mapping out what we wanted the user flow, from lead to order, to look like:</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Service blueprint for the experience of a user onboarded onto the platform</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "74.58333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAALFAAACxQGJ1n/vAAACo0lEQVQ4y0VTXWtTQRDNz/dRUEFRRAR9FIzVBqliBaWPglrQhzZt0ybp/dr7vd/HM3sTe8lkdmd3zpw9Ozuz2qCuFELwcM7CWosYQ5oH7+Gdgx7HZNZYpC/uLI2j2H5mZ/L/7ecf3Hv9Fs8Pv+Dx/AgP3izw6MNXPHx/jGdH33F/foxXJwu8+zvHp+VnfDxf4PDiBE/PRrxYarxc6vjk3OCyswQkeNsPuLheY7Xe4OziCsvVNS7Xt9iWHbZ5g9WmwjqrcJNl2OQFbqsMtyrHJWOrQiFrh3hV1Gj6wc601qjbHqrrUdYtqrbjgkGuVri6WeB8tSDgAllFnx2wyAHUcI1Wj+idRmM0Wutiaxxs5JFFs6YlEHX0IaRxyXFdZ1DVKaryFErRql+M/UZZ/EClNjDUtm4b9NR20JqqAyFGOxM9R7JsyMz5wA1kXCtoXsCoAzdHjmnieSd1Y7DNCrQsXDctVN0IiWiMmS7FsVI7GjjekyWg9hH8wYXIijIPsGTuaIPzMPSGazHFeEZSs6Tmp4u2s5GU845t4aZW6a1HRxO2lm3DTB7Pc30yaSVHG3djAQ4h3LUNJxTYJAA5vmFFYTmyQIrtmEhsoMl+5/3//bu2vAOUI3tpYJpSCkVRJC8XJAmOTJqmSfGqqiBaCY6h7rKvLEux2FBP74Od7cGGYUhJeZ4nL1IIoLUOGftvu91is9mkffIJsMQlRosCTvZ3DCU5MbI6JYgsYcdS/DCwC7h3P9/7Xvcw1sTpFcbEMIoJHhOiGcfdnC+aqHtvjY7e7eNTTPZpxtnLHMvlRDPbV5UjyhFyPi3x8oLkE/aiXU4ZRK9dvyUvx6xVzaZXqS8pTxCGisiK4isGFS+AvlMSZ53kp9i0RqAUJ0PmuLQuufQ1ieX/AAingG6ScehqAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Journey Map",
          "title": "Journey Map",
          "src": "/static/ac1baf32d454a4aa4577212f9bf89d40/d9199/ca-journey-map.png",
          "srcSet": ["/static/ac1baf32d454a4aa4577212f9bf89d40/8ff5a/ca-journey-map.png 240w", "/static/ac1baf32d454a4aa4577212f9bf89d40/e85cb/ca-journey-map.png 480w", "/static/ac1baf32d454a4aa4577212f9bf89d40/d9199/ca-journey-map.png 960w", "/static/ac1baf32d454a4aa4577212f9bf89d40/07a9c/ca-journey-map.png 1440w", "/static/ac1baf32d454a4aa4577212f9bf89d40/80cfc/ca-journey-map.png 1844w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">High-level process for our contractors</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "70%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAACxLAAAsSwGlPZapAAACoElEQVQ4y3VTS09TURDuWsUCbSnQ121LKUJBCkb/AAvcaKIb/QUoAqGSQAChElMQiSYsUPARUXFh3PgjNFIeLbRQoK1Wje4kPKwFVPycObe3uYR4ksmde+bMnG++b47GYvfA4qiGrewkHO46OCvq6OuV/axJFJNy8fpczOGWz3Iu12DT2Jw1yDdIaGnvxlryM2bDSwhFVjC/GMPcwjKSqa8YCNxF4M4okp++HYyHlxH/8AUtvm4UGh2wUi2N5KpFXoEFgeFR8Pq+lcGPnT/YSO9hfXtX7E1OvcbLV2+y8R1sZ35jU8R3xN4gXcaguIscwqttnYjEEngXDOP93CKCoSiCswtYjX/EwK0RBIbuYTWRwvRMGMH5CJ2J4O10CNGVBJrbuqBTEJqlKpizPFoVc3lhqDiD49ZqaMny7F4cc54i3wOtrQYG92lY3fXirMwd5VMNs70KGkUUNi7MtxSVutB47hK6+ofQ3umHr38YPv8I+f3o7BtE4/nLKDZXyDkEyKIqrOGqMkL5Fnu5F0fzTRgbfypztpnGxnYGG1s/sU788hqbmERBkV2oy/kClEDoOYhQXfD+w2ciOfPrrxCJhWCf14NHz6mgQ4hwIF9G6MltKi0bTeVoaLyA1us9aLrWgStEehONVVNzB1p9PWg4exEl1hOCwxxCRxahLIrctiIQ/xvNbuhLymAg9XRSDQqdddBTm/oSl4gpIpikymyOnH+4ZZpLrd6GXv8QVmnQgzQeszTgMzTQ7MfiKdy4eVtcJnPoOSyKumV7eS2OaEsx/viF4IsHOL23j/TuvuCR18STqf+LouaQv/xytDoreglFgp5dcD5KTzAmntpMaEmg7hsYhq7YCX4UoqCqw0MtK1+TrRKlaiMRWAj2OaaeXXX+P+tQRh+5Fc5dAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Userflow",
          "title": "Userflow",
          "src": "/static/f47aaca2253a8fa7b5d722187e31dcd2/d9199/ca-userflow.png",
          "srcSet": ["/static/f47aaca2253a8fa7b5d722187e31dcd2/8ff5a/ca-userflow.png 240w", "/static/f47aaca2253a8fa7b5d722187e31dcd2/e85cb/ca-userflow.png 480w", "/static/f47aaca2253a8fa7b5d722187e31dcd2/d9199/ca-userflow.png 960w", "/static/f47aaca2253a8fa7b5d722187e31dcd2/07a9c/ca-userflow.png 1440w", "/static/f47aaca2253a8fa7b5d722187e31dcd2/29114/ca-userflow.png 1920w", "/static/f47aaca2253a8fa7b5d722187e31dcd2/39f45/ca-userflow.png 2184w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Map of Masonite's retail channels and product offerings</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.833333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAABCcAAAQnAEmzTo0AAACuUlEQVQozyWSy4sjVRyF87+58sEo4ggiOPhYOOhiEAYRmT9AhAERHcaNS9Gl28GxHXpIOp1OTFJ5VqpT3dVJVeqVet+qSuV+3mkXP7h1H4dzzletVBwI8oayqCiDBFk3ODuXxdIgF4KqOSDqmijPqZtGfTc0TY1sSij2/8+x5qDu5Y2kZQcxnf6QMhLoJ6dQHuj0Btx572Oed8+5MFbMHBsrzzCTGDNN8f1rbP2MaDslsqcQr9jsPf7eVbT8rEZ3M4ogJRkbpLbP9z885e6Hn/NXt8fQumJqb1m4O1ZhgJllhIGFNXtJvNWoQh1SAzdyGQQ1rVhF3YYpZSKQYcRyvOD1dz7is/sPedY+o7tc8FLTOFvMWXrurcNXgjvjHJmYyOwK8jVG4PO7pQQtN+LkbIgIC6zTNnpf4877n/L23U941ukyuFrTX6+ZqtgLJTj3fcLQIjB7CG/OMVkrQYO27fHlUNBKK0lwUHvKJUGBMdG5/+BbfnzyK0vrBr8q8RQct8hxshRHwREiUg8cDplNnTlQOZzv9nw9Lmi5+4yL8VwJZmxHIwy1fuPdewz+1QjLgvZ0wmy7uY1kKSg3SjDa29jGBb6lUe5NEBZdJfhw1tByFN2eviH3Evz+DFPTeUsRfnHaYV+XdHWdwfqSuYq8CjyuVIdxZCt4ExXX5JiqDkuLiYL3c0dXkctGhqWUIikkUSb9G0d+9/iJnEwXUhwbuY0jdS6kl+dqMukVhSzySNapLY+FJ5vck5DIc20sv/nlN9naeDHd0ZxCQdkNRlyrDl978wP+edFWDit6yt3S97iMI0U4vv1tAkV5PTohsTWO8SU0G7rjPg+e/kFrXzTiOj6IIszEwdwJ27DEF189Er2LoahUOzdpIrZZKuwsE3b+anKRZ4HIPUOU0bWoYkvQ+GKyWoif/nwu/gMHixfacj+hsAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Masonite Channels",
          "title": "Masonite Channels",
          "src": "/static/1c3a68bb80ad3f0b8ca5151697c19c55/d9199/advisar-channels.png",
          "srcSet": ["/static/1c3a68bb80ad3f0b8ca5151697c19c55/8ff5a/advisar-channels.png 240w", "/static/1c3a68bb80ad3f0b8ca5151697c19c55/e85cb/advisar-channels.png 480w", "/static/1c3a68bb80ad3f0b8ca5151697c19c55/d9199/advisar-channels.png 960w", "/static/1c3a68bb80ad3f0b8ca5151697c19c55/07a9c/advisar-channels.png 1440w", "/static/1c3a68bb80ad3f0b8ca5151697c19c55/29114/advisar-channels.png 1920w", "/static/1c3a68bb80ad3f0b8ca5151697c19c55/a5262/advisar-channels.png 2388w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Use Cases 👩‍🏫</h5>
        <p>Our research and qualitative interviews identified the use cases for contractors:</p>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>I am a Contractor and I want to be able to send quotes by email or hand over a printed invoice.</p>
        </Box>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>I am a Contractor and I want to track how well my sales teams are doing.</p>
        </Box>
        <Box marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>I am a Contractor and I want to be able to see insights from customer preferences.</p>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Refactoring the UI 🧼</h5>
        <p>We were using Material UI as a design language throughout the Advisar platform - helping us design/develop quickly, as well as having many standard patterns given to us out-of-the-box. However, our implementation of it left much to be desired when it came to consistency, usability, and accessibility.</p>
        <p>The first step was to conduct an audit of our UI - so I began taking stock of what items were hindering usability and context. We came up with a new color theme, typography, and spacing units. We also looked at how we were utilizing layout and how it was impeding context and affordances.</p>
        <p>Rather than make changes to our UI feature-by-feature, we decided to refactor our base styles and components from the start. Doing so let us have our <OutboundLink rel="noopener noreferrer" href="https://en.wikipedia.org/wiki/Mise_en_place" underline="true" target="_blank" mdxType="OutboundLink">"mise en place 👩‍🍳"</OutboundLink> setup for the features in our product roadmap.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Creating the design system in Figma for faster prototyping</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.58333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADhUlEQVQ4y22SfUzUdRzHb/VHzY1qU1s25lhrtf5QV1t/lMWaimHB2tBWqSlPWhvyoEiZKGJOooSCEwPpxD/MAdb0zkTGc6fj4g64JwRudxwH9/C7+92DPISW3HWvfh5xra0/Xvv89/q+P5/3V9bccoUa+TnkdQ3Ia+Rc6tYgN4mc0TmpHHLTNOjAerGF0RoFo7WK2Jw2jeD1+xkbG4thsViiNpsNh8MRlaVs207CyiRWrnmBVauTeG3XAR794DNkW/chS9nPc7tLKHv+FXJlCeyXPcU+aWquXic0P492YICOjg66urqiZrN5SXi47FvSdhby3sfF7NhdxPvl37Miv5ZHciqR5XzN+uJaGtclc/qxRCpWrOX044noVTcJzM1iMhrRaDRMTExERVHE4/FEZTd6h2huG6C1XYeqfYDqWzaSu/xsVc/w5q3f+eRXAWthOZY9h7Bml2DNPIyzX4c3FMRsMmGSeChyu91IRGX2aS8u/zye4AK+0AKjwgydzllu+x+gDiwy6FvAPzLGvMXOPdsUC1YHPqcLjyAwPj7+cE0EQYgJYwlnZmdZjIQJ/xUhEo0yNzdDSBSYDYoSPu4GfIh+kUDQH0cSSGk8TE5OsiySWEr4c6maxg/bOP9RGwqJpmNqjud1cSSrneJdbVSU9HD0VCe5BSpyC1XkFCj5TWshID1ktdpwOp3xlWMJv9zSStaT59j7RB3ZCWc5mHKF1A2XSF6r4NVVDWRsvMxbaRdIfKlaoopnXzzDzQ4jM3fF/xf+tKeX+peV1G24RsP6q9Rn9nEoo4e8ze3kvn6DIzu7ySrsZssOFZu3K9mUoaTvtoVQ0It90hFfOS7UVBnpKdLRe3AIddEgHTVmLlQYUJww0Fhq4PJ3I9T8YKS8apiT1QZp6hk2OhBFgX+ajd8wJrT2T2JXOxEGg3h1AezDAto+K6NaLzZ9CKspQL/WjnbYi+FOCL05iGNKQPC4Y8UslxEXTlXOMX3gPu7CMGJBGG31Pc6XLXDxaJimz6GlapFvFHMcq33AibMRjssjDJv9iD4XLrdnOeW/Qts7fzD+TIQ7Ty9iWx2mPf0+J9+IUL4uwhdJYSq3/cmnpZCeDWlZ8G4mqDVBggFJ6FpK+J9vU9Dj4G3lBKlKG6nXHeT9oiO9sZVN9S0k1zWz98drnOqsJ1/1FQUS+aoKtBY9fsEnCV3xhMu3/BvH4mHL7h1p4gAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Advisar styles",
          "title": "Advisar styles",
          "src": "/static/bffc132e1792b7d5a62f9ffba7cce947/d9199/ca-styles.png",
          "srcSet": ["/static/bffc132e1792b7d5a62f9ffba7cce947/8ff5a/ca-styles.png 240w", "/static/bffc132e1792b7d5a62f9ffba7cce947/e85cb/ca-styles.png 480w", "/static/bffc132e1792b7d5a62f9ffba7cce947/d9199/ca-styles.png 960w", "/static/bffc132e1792b7d5a62f9ffba7cce947/07a9c/ca-styles.png 1440w", "/static/bffc132e1792b7d5a62f9ffba7cce947/29114/ca-styles.png 1920w", "/static/bffc132e1792b7d5a62f9ffba7cce947/e8950/ca-styles.png 2000w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">A refactored dark mode for better accessibility</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.08333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACoUlEQVQ4y62U208TQRTG+3+IFCi0XEpL6RXKpdwpl4JIwKDGEGOMMTE++OCbDz765p+GaFtKael96e52dwsttGQ+50xRqoZEEzf5MmdnM798Z845a+ruG8Rd6rEOoaOrD6O+CUSTWSjnDZTUc0iVi9/FzrQauJipq3fg74HVqzuBP9Y/gAQhidhm/wWocod08MZNu4RDAaSDZks/7ndb0dljxT2zBR3mXhF39tj4ey9G/S2gbFwiX9aRlw0UlaoQxQXZYORcpGy22DDs8mM8tAhfcAZz4Q1ML6zCNzEr9iz9w3B5x/E1cQpZu4CiVaHq5yiWVZTKFRFzsaKs03UwEznzT85hc/cZ5lYe4OOnz3j7/oOIaW/Q6YHLM4aDeApnioarRgOMMRyns0hnC2AAGs0my5XK5LYFJGdrD/ewvbePJ89f4fH+Sw57ilW+N+BwC4cHsRPhqla/hFSWkT7NCMmKivrlFcvkJboOZqK7cgemEN7cxfLGDlb4urr1iK87CEe2YbO7fgIluYJarQ7DqEI3DFQ0HbpukGuWLZZbQCrI0IgPY9MLmA9HMLscgXc8BGdgGpad1+jud/Aqt4CUcoOnrKoqFEXB9fU16NE0jaVzRVEcE1XSye9ofmUTIV6MpfUtBKbmEVpcw4s379BpGYDbH8QXfof5kowzni4HQJIk7tQQQFkus1SmcAt0uMc4YJ1XOILgzBI83KGfV3lydon3pJ07DLalXOMgHc1mE20PK0hyK2VqbOvgCOy8dRzuAE/fywvhERp0ekWPEvDwKI1Utohv8QQOo3HEjpI4Ok4hnjhBIplix+kc9WWrsQlKB8ktrV1ctNI3MSk3jU1NHU1mEDvJIcbfo7dimZL677NMo0cjVtbrYmradDt6//vn8B1j4SLJIj8PUQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Advisar styles",
          "title": "Advisar styles",
          "src": "/static/a47371e92cea60213db33db4788028a6/d9199/ca-ui-update.png",
          "srcSet": ["/static/a47371e92cea60213db33db4788028a6/8ff5a/ca-ui-update.png 240w", "/static/a47371e92cea60213db33db4788028a6/e85cb/ca-ui-update.png 480w", "/static/a47371e92cea60213db33db4788028a6/d9199/ca-ui-update.png 960w", "/static/a47371e92cea60213db33db4788028a6/07a9c/ca-ui-update.png 1440w", "/static/a47371e92cea60213db33db4788028a6/66632/ca-ui-update.png 1504w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Building a component library for our version of a Material Design Library</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "55.00000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAABjUlEQVQoz22RPVPCQBCG808trWz9C7a2NspY+AtsbLSg0FHGUWFEGEj4/mYGQsh973qbC4FDt0lu971n390LhqnG2w7DrQKkAPfBdneC08Uq+zd5ci0Nlqx2yoyX7w7n0B8vsuvByAJLUYpzbnKgE7W6YxxOl3+BVhsm+gg4g6g/dcDIFs/eN3jXZYWIZF8/HSy/fSPjAnexsE3PPzd42dx6wEY4hMfnKsZJCsHY2r8KGZbn0hs56s+w3h6gkKoAksObiOP9SHjAwWQJtWYPUyYgaMQKT15jvA7TXISZw0q1hU8vNdymvADS7k4rMV7UfYe1Rg8eyh+4ihMI8CBc3Y3s56Fw7muLne+qEAhraS4MagAPmkiNQvsPRZqZ3aM04OW3UgNX2gGVLa4koDA+kGCcgAfuaETScu0DpTbAVKaFgLotrMNE+UBmO5JLgP0KCEjatfSB3LraCEVnCGgMgrmu+WWbU7nDPRAyIGmZhgKWrcIYyLVu5P8c8twhHgFJG0vIH8pV7HogFtk08Avg6VVfD+pR/wAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Buttons",
          "title": "Buttons",
          "src": "/static/55cd5339ff5cb69944f55891eb74c19c/d9199/ca-buttons.png",
          "srcSet": ["/static/55cd5339ff5cb69944f55891eb74c19c/8ff5a/ca-buttons.png 240w", "/static/55cd5339ff5cb69944f55891eb74c19c/e85cb/ca-buttons.png 480w", "/static/55cd5339ff5cb69944f55891eb74c19c/d9199/ca-buttons.png 960w", "/static/55cd5339ff5cb69944f55891eb74c19c/07a9c/ca-buttons.png 1440w", "/static/55cd5339ff5cb69944f55891eb74c19c/29114/ca-buttons.png 1920w", "/static/55cd5339ff5cb69944f55891eb74c19c/31d79/ca-buttons.png 1954w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Feature Discovery 🤩</h5>
        <p>Now that we had our UI and base components built, we needed to begin mapping out the refactored app architecture per a userflow that made more sense. On top of that, we needed to start ideating upon our feature redesigns while considering the feedback we've received from our qualitative research.</p>
        <p>Features we need to address:</p>
        <Box marginBottom={designTokens.space[4]} bg={({
              theme
            }) => theme.pinkTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Quotes/Orders</p>
        </Box>
        <Box marginBottom={designTokens.space[4]} bg={({
              theme
            }) => theme.pinkTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Payment Flow</p>
        </Box>
        <Box marginBottom={designTokens.space[4]} bg={({
              theme
            }) => theme.pinkTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Dashboard</p>
        </Box>
        <Box marginBottom={designTokens.space[4]} bg={({
              theme
            }) => theme.pinkTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}>Company/User profile</p>
        </Box>
        <p>Features we need add and test:</p>
        <Box marginBottom={designTokens.space[4]} bg={({
              theme
            }) => theme.blueTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}><strong>Leads:</strong> give contractors a way to asynchronously interact with their homeowner clients</p>
        </Box>
        <Box marginBottom={designTokens.space[4]} bg={({
              theme
            }) => theme.blueTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}><strong>Sales Insights:</strong> enable contractors to see the health of their business</p>
        </Box>
        <Box marginBottom={designTokens.space[4]} bg={({
              theme
            }) => theme.blueTransparent} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}><strong>Product Analytics:</strong> let contractors view customer preferences</p>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Information architecture for a contractor's view of the app</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "49.16666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAABl0lEQVQoz5WR6U7bQBSF8wCtiHCwx+N9Ge9JTLwQ1CAELaD+6vu/zJze69gRilCl/jia/btnzl35cYOiGUgj8qpHXvdQ5QGq6pCpPZ5shZPMYG5crA0J4SrYXg7BcrNJtpfp85jrlRfViLN7JNkeYbJFmG4J2CHKWkRUrBUJer+CEBEMK4ATlBCOmsDSL2hdMVTPRfSKyUk5wFP3ML0CJq3diFzvfqBsn+CodlLTvUyPc9oPy/HiMGsekTdHLWQCm8ArmwHk5HZ4w3r8wF28haQ9J6zgkriyH+9Q7E+QNJdhjdvxHevDKyxyqqoH+OWo76ItrKA8A/nSzfCObw+/YaQtV/qUTz7JctJpDJIdJMmoHyHIMX/7pvupv5/+wMh7AvID2jSLASbZN2fgArpWXI2w2mdslnv01so7bdZHWFEzA/mAHXAOztzFK4fTnPMmYNC9YkMxXAo5qbZlOme4PJj1GXAN5Az97ACr/wWDXJrUvCke6q5YuvwvwFdr1RwR8fcoP/7uAjzf+U8gj9wEdirmaOazC/AvTsMXnDgRnAEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Sitemap",
          "title": "Sitemap",
          "src": "/static/317f5ab5570ecc497d04cda63f2d47aa/d9199/ca-map-contractor.png",
          "srcSet": ["/static/317f5ab5570ecc497d04cda63f2d47aa/8ff5a/ca-map-contractor.png 240w", "/static/317f5ab5570ecc497d04cda63f2d47aa/e85cb/ca-map-contractor.png 480w", "/static/317f5ab5570ecc497d04cda63f2d47aa/d9199/ca-map-contractor.png 960w", "/static/317f5ab5570ecc497d04cda63f2d47aa/07a9c/ca-map-contractor.png 1440w", "/static/317f5ab5570ecc497d04cda63f2d47aa/8f5d7/ca-map-contractor.png 1475w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Managing Quotes for clients 🧾</h5>
        <p>After a contractor <StyledLink underline="true" to={'/work/masonite/door-configurator'} mdxType="StyledLink">configures an item</StyledLink>, they're able to add to a quote for a client. While in the quote, the contractor is now able to easily:</p>
        <Box marginBottom={designTokens.space[4]} mdxType="Box">
          <ul>
            <li>Assign a team member as the salesperson</li>
            <li>Return to the configurator to modify an item</li>
            <li>View the door details in a collapsable table</li>
            <li>Reserve inventory before purchasing</li>
            <li>Print different types of invoices for internal teams or external clients</li>
          </ul>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">The new quote/order view (dark mode)</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "78.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAADNklEQVQ4y32U229UVRTG59WUi23H6dzOzJwz5zYz58x92toABtFgHzQa3/rAC2AQC9GQoH+D/ge++HcQi5dI2ipto4TSgIg3SKdA2xm0tPTCz7XP2Jpa8eE7++y113z7W99ac0L317Z48OQpC6ubNB9vsPB/kJz7a5Irv2nuiXd4QuqhNq2n8Af/4BG799uxh49XWF7f3BtfJ+AJqYfazNy6zaVvxhmbuMrlyWm++HZa1inZTwWxDqa49PWXjF35ijHJuSyxz8evSu4Md9urAU9IyVU3nDr7AQe6o6SMApZTxck3yNjVALpTwxDoTh0rVyfvqXgd3a2TsSpU+o8wffMn2vxNqF7eOf8h3RGdeNJGawxTe+lNKgOvUmy8gu0fJlsYIpsbIj9wmEKjTq54hHL/UUq1AfzKAJPfT7G0ttopWRGeu3CRTNYSdXncUo1idVBuL4uqKmlZlRLNLJNyyziFHDl/gGq9H8MqoGVLXJm5xkJ7iVBz5QmtjTVGR0eJ90bRYikyRg7fL5I28sQ0By2TI236FKwiQ45HRjcx7IrYUCESt8Qmj8nZHwPrQveWWzxc+ZOTn3xG1/FT9Lx2EsOt4ZWHxK8a8ZRLMp0jmskzaPuMeGUiCZtM2iGdsonHdKnMY+L6rY6Haq7aMjJnPv6U54+OEH55hKQ0wrRLZMwiml6Q1ReFolhIbXmPyiXx6jH0wePkj72Nd2iY8R/mOgq3m3L23AXi4QTJhEmfURRV+YAsISS6VRK1Vfo0KT0rhIbPvuHT9L5+hv1vvEffobeYvHbj312+SDhqkJDkuDRAlZkUMuWPpueFyJOGueimR1/cpPdAmPDBCN1dPUHZysMdQiX1xOnzPNcVERUu4XSBSCxLQkhjKaWqiOlK2YaFYbrSCJP9vRoHX8iwr0cLPJ24fnM34bvvf0RMZtCWccgWX8TO14Km2IUGjtfA9fpx/Qa1hoyKUw7sUOWrKizJ/W7u9nZTOn/qOw9azP42z9zdhR3c+L25g9lf55n9+RfBHclr7jpTuepjsevjsLgBy1vPgEzB4voW88uLNNstljb35mzz7ChUqyr/WVDnasQ62PrPc8XzFyyfTgyvxIySAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Quote",
          "title": "Quote",
          "src": "/static/ea433e30489bb8db16aac1d0228c43d1/d9199/ca-quote.png",
          "srcSet": ["/static/ea433e30489bb8db16aac1d0228c43d1/8ff5a/ca-quote.png 240w", "/static/ea433e30489bb8db16aac1d0228c43d1/e85cb/ca-quote.png 480w", "/static/ea433e30489bb8db16aac1d0228c43d1/d9199/ca-quote.png 960w", "/static/ea433e30489bb8db16aac1d0228c43d1/07a9c/ca-quote.png 1440w", "/static/ea433e30489bb8db16aac1d0228c43d1/29114/ca-quote.png 1920w", "/static/ea433e30489bb8db16aac1d0228c43d1/4c041/ca-quote.png 3328w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Quote on mobile view</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "83.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAACYUlEQVQ4y52UTWsTURSGuxBacRFBVwru3blx6V9w48aFICiCVkLdFC2KoCAuFNzYxtqYJmnxY1X7sSktpVIaNCrVJG3SJGWSSdOvZJJMZiZJk0keb9JWqiDteODlcpmZ575nzjm3LVFU2S+pkGe1ZPAlEub18DAOj5tel5M+t6ul3sFBJn0+ZENHUgstJVS1sft9o+0PmHi4ks+xZVa5dsvOUdsZbMdP095h40i7jY5jJzh56iznzl8gmtkkqWsHA5uS9SL27h4uX7XTeechXd2Pud55jxv2B3TdfcLFS1f4mZRIiUwOBCaKReFS4dWgF3kjT6li4t8oMbVWQdPK6Cb0u7x8jUVJl0uHA8ZzO8BwPEVkRSYQW2UhmmYpKrGuaDicbmvApkOHy0Nc3mBTMfCFF5gOzpFcy5Evmf8BzO0AM0qBmkhRq1YobOtsV+s0Y0C491t36CabyaKJfbGgUjZK6KKqzXB5hvBHl639w6ZDRclRKIiqy7KA6ZimuQ9ouSge1KKGWW+IVKtUBUzVNMQWp9WU94A10WfUqzQqpdYqRZbIpFMMCPeWgU6Pl5eLOW4HKvQEDe6HDG76czyK18VhQ3wTwFUrwH63l0/hFJOxDDMrGeaSBWakHN+zNdE2Hitto+5MigAmJJmt9U2UjMJ2pYqhGTQau5MSjx0OmNSKxJRsa1J0w2hVtS4otVqNvXjjHuLzcoR0pXy4y6E59C/6HHwcGWF2dpbpqSl88/MshkIEAwGePntOICWT2r3C/gncgzZf/JGQeDc2xvumxsf5MDHRWt+OjjIfCv6G/Q38BXQJtA91LcJ0AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Quote",
          "title": "Quote",
          "src": "/static/2923b3e38c3b65ee106daa77bcf246fe/d9199/ca-quote-mobile.png",
          "srcSet": ["/static/2923b3e38c3b65ee106daa77bcf246fe/8ff5a/ca-quote-mobile.png 240w", "/static/2923b3e38c3b65ee106daa77bcf246fe/e85cb/ca-quote-mobile.png 480w", "/static/2923b3e38c3b65ee106daa77bcf246fe/d9199/ca-quote-mobile.png 960w", "/static/2923b3e38c3b65ee106daa77bcf246fe/07a9c/ca-quote-mobile.png 1440w", "/static/2923b3e38c3b65ee106daa77bcf246fe/0f882/ca-quote-mobile.png 1584w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Users are now able to select the type of printed receipt they would like</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "78.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAADBUlEQVQ4y32Uy09TQRTG+SPcGaNRQqTQQqHFgkUpbUp4BClpBV9oxYVR4yMuTIxrF5hANMbER4zRxKUb9+504SNqjZGFCqS0vb0tve199PZ5Pmemtjx83OSXb870njPf9MzcJknOYyuJJMf4PTYQl9aJSX/ExJXlkCQb1MQT/gV/MbVmQMnmBdmciZxa2ApxlZIG8aJN3EUyZTaou+RjXjSb06HrGhRFAVEVf3mIPWxxlRIyKxiXdERjKlbjKmIJbRPRWA5rGZUV1KGqmlCOpmkNDMOgUqmMRL3gtRtzGA2cRPDoWYwHT2Ns8tQGwhiZOIHJqVmsrsZRKpUaRQWGgWKxSNVqdd3h1PFz2NvRD+f+YbRY3dht6cWetj5BM2N3qwutnQcRWfwOtVCCVqxAL1UbqCUig801HM6cuSKK7R+cgJvR5zmEA75J9HsDIua/dbmG8PpDBHFFQyKrQ8qyhik65JyBFVZRMcusKWqtKcfCF4UDi32AOT0gxnX4HHfd0ePD9+UoKqwDhQqhyHrDKdSUCuUNDqdnzsPqGBSOnO4RtHcPCmxOr9C2Lg/s+/z4/DkCg3XbzOdhmiajpvm8ubkp0zMXRDLfnt3lF2OrwytccW1jLvn8z6VlcUZ4cqVSQaVcZgeGe64SV94UiRc8cuoSrL3D6PUFYe8fR7trmMUjAlvfKCyuUdjdY0gm5cbB42WI8SVdxv2IQW9iBaRkleL8PzwSvoweXwi+QBgu/zQcg0E4vEE4vSE4PAFYPYfFQl+/LrJboSOVzkBOZZBmGllO4cUnid7+kJFI5GpbPhq+hOaOAdiYy7Yev6B93xDTIdjc4+j0sAUGAnj3flEc9qWVNaxEM4J4TEFaylIirrAt67UtT4Rmadt2C+1scdKO5m5GF+2y9InYFrpK3vlX5Dh2nT5++EaZHIkkdi0FkmwyV0L5dWXHhn0cnj1/iZtz93Br4RHm5h9u4AFu3X6MuTtPsHD3KX4spSCnC/jPx6TmMKsS6QZI+xs6Q6spd1RP2kRyc/wLYPHpPhqAFA0AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Print UI",
          "title": "Print UI",
          "src": "/static/11622a14246eb0d451bb9b31ed52d09c/d9199/ca-print-1.png",
          "srcSet": ["/static/11622a14246eb0d451bb9b31ed52d09c/8ff5a/ca-print-1.png 240w", "/static/11622a14246eb0d451bb9b31ed52d09c/e85cb/ca-print-1.png 480w", "/static/11622a14246eb0d451bb9b31ed52d09c/d9199/ca-print-1.png 960w", "/static/11622a14246eb0d451bb9b31ed52d09c/07a9c/ca-print-1.png 1440w", "/static/11622a14246eb0d451bb9b31ed52d09c/161ec/ca-print-1.png 1840w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">A printable version of an invoice for shipping receipts</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "102.50000000000001%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAAACXBIWXMAACE4AAAhOAFFljFgAAADAUlEQVQ4y11VyW7bMBTM/39B0E9oe3F6DHpsckrqAE2AHLpElmPL2mxtlEjK0zeUSKulMSBpkvP2pyu7SWHiFDbO/oEhePZ2gI3knGsiIg5u9veW96/cH5vMXbLR9EDzYatgiVMDk51g9gXsTpCUsIcjDOEFbGZyEgbJZQ0tj23T4dxrnMcR5/MZflhrMQwD+r6HEhjZm7zCSCHbLJBOhLLQcqnIMzRdB22Me+wg/2utHVHbtijKArvdDsfj0e0Jo0RA1TrLrpy5stBdj6ZtUJ0qNE0TUFXT3o/VlxWuP1zj7v7O7TtRoFMKphfhb4kQxp5QoReNOIxoyIvUkGuOUVxA3H69xafPH/H4/RF60E4Yzae7GIeJkFGTAAx6cOaRiCYpkTzOvvT+jKIIT09rpznP2qZFLZYN+elCqElYi++swTg7n76hlgwGyZyvRNv1eo2b1QppmjoBZVlCDeJnif6FUHJN06lCaERDkhAk4UwimkaNX19fcXOzQhxvQvQtg7jNXK7OhAmM+GCYo+tNpEkk4yO6guPx4QH3d98mMmOnWXw5RMmcNvQhCSUPB6OdyRwk7HsVBGRZ5kDCl+dnd8cL0ZK7rjACoWx0UcGMNhBSM5q81NjOZy8/1kgPezBODNXAt57QlR4JpbyY0PShD4JSldOSD6kNg8Q53vwWn9ZTBpDwUP5PKHmYHiVt9KzRiDwvJDUOsldBM575VPKgJXqXw1fchZBhl0OWGC/y4elUhD1J61pyzfShamiJZoX45hCHbiOE0k28WSSYUuKS1ISZs2BmA3+DBETP5i7aV4rhPXdpMJ7HoEE/NwZffq7TiOZeO3pQFaep7c198aKhJKYW6awAdpilZr7smNwsyWCyQCXFpdkuNdRiv5FKqVkddLhcZ9Rd61JT62LQeIcz9w3Lc5uGgEyEs0PZy5r3FN0+RxMnaDYJWkEnaN6k//3cIP8liN5R7BKUaYa2kmJwhFn4jIQGGxJc6nr5HRnl8ihn523u1v5z0f/ZQ0m5Lds/8RfqTk1sSQCf2wAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shipping receipt",
          "title": "Shipping receipt",
          "src": "/static/460e66de9ec290d611d9412aa1892e7d/d9199/ca-print-2.png",
          "srcSet": ["/static/460e66de9ec290d611d9412aa1892e7d/8ff5a/ca-print-2.png 240w", "/static/460e66de9ec290d611d9412aa1892e7d/e85cb/ca-print-2.png 480w", "/static/460e66de9ec290d611d9412aa1892e7d/d9199/ca-print-2.png 960w", "/static/460e66de9ec290d611d9412aa1892e7d/07a9c/ca-print-2.png 1440w", "/static/460e66de9ec290d611d9412aa1892e7d/29114/ca-print-2.png 1920w", "/static/460e66de9ec290d611d9412aa1892e7d/8e5fb/ca-print-2.png 3810w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Payment Flow 💸</h5>
        <p>Our payment flow was broken and tedious - we needed to address issues that were prventing contractors from moving a quote to purchase:</p>
        <Box marginBottom={designTokens.space[4]} mdxType="Box">
          <ul>
            <li>Offer multiple payment options</li>
            <li>Refactor for better context</li>
            <li>Fluid for mobile layouts</li>
            <li>Add temporary state in case user leaves</li>
          </ul>
        </Box>
        <p>Contractors are now able to step forward and back, add warranty, and choose to pay by credit card or third-party credit vendors.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Refactored the final step of checkout</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "77.08333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAACXBIWXMAAAsTAAALEwEAmpwYAAACY0lEQVQ4y5WTy24TMRSG8wAFFcoCKeGyQEgsyp5XYMGqqJXaZkhVEhAPwSOw4wlgiYQAlVKJCBB0g4Rgw0WCNheaNIFJOsnM+DK2f449aTLQSqjW/PLxZT4fn3Ocm5rOY2X1DvZbs9XBoycbePriFZ5tvMF6dRPPX266PrXfYZ30eK3q1tfIrr59b65em8f0qbMmd+xEAYvFmwjDIRhj0MZgGHMEwwixkJAaEMqMlRiAPoRcIqbFhGzaYryV2yDnLDCP5RsVMCHAOIdMFOI4RhxFdEhIdoREyrFkkiBJLIawWgAJg4pDs+hVQCyTm5kp4MrcKu59F7j7OcbrLm2SHK3dDjgXLgzGpOEYdeSZwsM6w4Maw/0vfVRbkSmVKpiywON05YViGa0gRGvAMBCKeAy9ng8hBCZEM0ZKpfBjj+Frj+Fbn8GXMPaWzkMbw6JXhpYChqS1QqcXuhhyYccGSmuScbZUmmJJY1rzac8git2RSxNgGkNNPwmKkfXiw9YeGp0AMSVJJPoQKQflVjS2eVryMsAiAdObURZpE3QC//cvl5z9+UlL9zjoSAeAy38BFaKYYTgMXRnZuaxsWYmjAG3ZRFQyvu+73oYiC7TNxvRIHgqZIAgCB8s25yGty1EM/wu0AE7FXf/4CX3ysNvtUvn0HHwwGDggpzBELlkKnA5m9Jq0A5ZToH3L9tlkG6ef3WshCVc6+oC3kxSlXbF0a1TYJ8/g+oKHdnsXjeZP1OpN1Jo72G6QPdI2zVlt1RoTZcbNnbaZm/dALJM7XbiI/LlLOH/hckazmX72n7VDZSyDWOYPl93Xf1oY0gkAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Payment Flow",
          "title": "Payment Flow",
          "src": "/static/1af6cd33c57e5b9dc0c2f64411df2c50/d9199/ca-payment.png",
          "srcSet": ["/static/1af6cd33c57e5b9dc0c2f64411df2c50/8ff5a/ca-payment.png 240w", "/static/1af6cd33c57e5b9dc0c2f64411df2c50/e85cb/ca-payment.png 480w", "/static/1af6cd33c57e5b9dc0c2f64411df2c50/d9199/ca-payment.png 960w", "/static/1af6cd33c57e5b9dc0c2f64411df2c50/07a9c/ca-payment.png 1440w", "/static/1af6cd33c57e5b9dc0c2f64411df2c50/29114/ca-payment.png 1920w", "/static/1af6cd33c57e5b9dc0c2f64411df2c50/a71c9/ca-payment.png 3480w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Dashboard 📊</h5>
        <p>A dashboard is a high-level preview of crucial information important for the user at the moment they are looking at it. But we want this space to be utilized for the analytical/operational tasks for users rather than just for outbound links.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Dashboard widgets</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACFUlEQVQ4y21T2XLjMAzL//9epy/d6e5buvFtHdYtLCg5SZtZz2AoyzQEEtRl9AVryJhdYizYYoXKaJD1wj3JGUJtcQz/RR35ffC1Xhaf8ffrC8MwoD2VhFrjcA4+RoRMsm+Esu7E9SRr+1XWzKmXmYQff35hUBOO7LGbHZ/v7/h4e8Pw+xM5hCeZxIgWbyd5R61nTidU1mInDipyoiomRML5AOPCQ5nkTrvGfni4AhypwOUKE0vd2bZeMhdKKXiW+PowF6nUXiaVCeG+K2yEsQec87CMWpu6maOXLD2UD/wXhf0TCIlLGT5FWMaJ5czaYlXs7XFgHEdM80wyy0MLCTUJrfSzXsRZzVMsS/U5ESTKEblkcai9S8mbMvIjAnu67ztFOPpHGaUgeFc1RXWFJBRHCz9UnpZLalFy66lWFFrvsa0rjDG4Xq+YpgmZh4G5Rqu6UP0YQIWRJCkhUWEUQ6hAIO+ZJXuaM/FQiVJu4L4oFXTCynxfjQ9d4cRyxCElw33QUX1gtg6bj21v86krZEnrsrS+3W43LFzLwYUKrTXPHoqDtxO0vc3Yfc7uEEIXaBDLFZVS7rptjVAUendUZU+X+8TX5+Q/rlffGx6EARtJEl0XIs+epiSz6qUdVbGiNofDj4n/iXad7oTs0UJlorI9nIJEMnF8prJbz39V+IJvd1jHzNHiYPPm6JAazBlpWr96jP8AZSE+w9BOjUUAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Dashboard widgets",
          "title": "Dashboard widgets",
          "src": "/static/08470307519723f2c269512b4e67df28/d9199/ca-dashboard.png",
          "srcSet": ["/static/08470307519723f2c269512b4e67df28/8ff5a/ca-dashboard.png 240w", "/static/08470307519723f2c269512b4e67df28/e85cb/ca-dashboard.png 480w", "/static/08470307519723f2c269512b4e67df28/d9199/ca-dashboard.png 960w", "/static/08470307519723f2c269512b4e67df28/bb543/ca-dashboard.png 1418w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Company/User Profile 👩‍💻</h5>
        <p>Our UI for viewing a single company or user wasn't following our UI patterns - cards on cards, illogical context, etc. We wanted to make this view easier to scan and make usefult for users who wanted to utilize this space while on the go and on their mobile devices.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Seeing the overall business an health of a company</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACVklEQVQ4y51SW2sTQRTO79H6UCvYB0FF6qM/zgefRQtSlMYLmiKCryKUVqRNmqS57iWb7Ox99r7n88wmqZX2QR349jsz5+w3Z76ZRutgD62D1/SptYfm25fYb77APnPzCnavx7td4nr68PEVhNsXjaOjY3Q6Hfr85Ss2t3dwZ/sxNu/uYGPrIW6toOKbtx9gQ2HrT3Cebmzep3uPnuD07LtolGUFHqQ+Jz0dJ30dPzoTHLdHzOOa1drZeI72YMYwV7iIqTO0qDuy4IWaaCRJijiOKU9TfNMCHA7n6A6m6Ax0LtaYNfTGJs6GOqaWh5krMXMkzBXmXkLnmqD2wEAUm6LhOC6yLKM8L9A1PLRNDyPdxlhfQJs5mBgLTJnV3Jh79Zqx8Dl2YTkRZiIkntPIsOFHumhEYYQ0TSkvKtheCF/GyFi8qAiSWWa5cgM5OxNxnC4tQsL1SZIgyXJivyiUCdyAjxzFCbcak0wz2FEKrwAKrvBzgpWUcLKKxQguKwqOfc4ryTnnwqjenMqKyAvk0kPIgPX5byoRhDHKPEOeL7taD6L6zlAVBfIsVSfixWqdU0kKWNxVgk9PfTzrx/R8EEPwkYPAZwQXQmuoIaWEEA5DKN+vF3wzitCcxvR+qjyIUXB36ar4Uos1FdxhGIb1hmVZXhVUHiKJ+CwpoWK/ghAylfWjvNzdb3DppVhxtRL01x2q2yx5MeOb+Dkd4nDSxsyOYNjBX8G0A9LtkMaWu3w21crbNCvQm9jojv8ZpNCbLJaCthhBOGNaMGtm/39ACrp1jijRxC+Y8PRrl/kVewAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shipping receipt",
          "title": "Shipping receipt",
          "src": "/static/4cd8320515a768ba3c25731fe3916e55/d9199/ca-company.png",
          "srcSet": ["/static/4cd8320515a768ba3c25731fe3916e55/8ff5a/ca-company.png 240w", "/static/4cd8320515a768ba3c25731fe3916e55/e85cb/ca-company.png 480w", "/static/4cd8320515a768ba3c25731fe3916e55/d9199/ca-company.png 960w", "/static/4cd8320515a768ba3c25731fe3916e55/07a9c/ca-company.png 1440w", "/static/4cd8320515a768ba3c25731fe3916e55/29114/ca-company.png 1920w", "/static/4cd8320515a768ba3c25731fe3916e55/c6a7a/ca-company.png 2719w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Viewing on mobile</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "83.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAACO0lEQVQ4y52Uy28SURTG+x9oHyhtgSFtykARrGltXBiN0Y2Ja6NRQRpboknVuHHl2gW1trWmNfFvcefCR2KUgFphsBEoLY+OdGCY5+eZASybpkxvcubc3Jz55fvOvTk9g04W7bA72tmDYYaFPzAFn38CrDcA73gQXl+QzibhHh03a9q1lPUWQ+/pBO7DfHC6WYz4zmGYzmwnXeg74YLN7gYbPA9m1I8hp+e/gEOARvZgcvoCrofmcSvyGPfuP8UN2t+kffThM1y8fA2DjrHO/w4GDrm8pMaN2+FZbPN1/N1r4FOhjnc5ETxfg6ACsZer6LO5qNbXLZDBnbtzyJeqSG5k8DHJ4X0yg2+JDVRqChaWXqPXEtDGIBSJYjO3gwQBv8R/4MPnr4j/5FCoCEcAtiyntgVslkV85/JIpvPI5Cso1zQ8jy2bLixb5opVZAq7yG0VsbVTQbZQgmz0cHHFYg9bliVFgSA2oGgamks3v8ur6+gdcJrPq2tgmIBlUcWfPQm8pEIhliwrKBIwtrLWVMhYVFiTFFTrDQiUDW0qKTbWkqnQIjA8E8WupKHU0MDLOtnWIROwQcDFV+vWFRpAXpRRqksQyK+u69BUBRIBXxwFGIrMoUoKy6JmgmVVox7KEAi40O5ht7dsPNqZ2QeQdAl7UtVU17nW3rzF8X4LtzxgH8GlK1cRT8SR/p0Cl+Eo0kilfyGbz2L+0RMc63ccDtyfcUaM0Qw8g8DpaQQnmnEqeJZiCg6GPXAe/gNTlq4Go7cxugAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shipping receipt",
          "title": "Shipping receipt",
          "src": "/static/0820e5fbe460914ee88029bda4a1c2d1/d9199/ca-company-mobile.png",
          "srcSet": ["/static/0820e5fbe460914ee88029bda4a1c2d1/8ff5a/ca-company-mobile.png 240w", "/static/0820e5fbe460914ee88029bda4a1c2d1/e85cb/ca-company-mobile.png 480w", "/static/0820e5fbe460914ee88029bda4a1c2d1/d9199/ca-company-mobile.png 960w", "/static/0820e5fbe460914ee88029bda4a1c2d1/07a9c/ca-company-mobile.png 1440w", "/static/0820e5fbe460914ee88029bda4a1c2d1/0f882/ca-company-mobile.png 1584w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Sales insights 📈</h5>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            <p style={{
                  marginBottom: '0'
                }}>How can we give contractors a way to see the health of their business and index on where there is success, and where there are issues with their sales process?</p>
          </CardBody>
        </CardContainer>
        <p>We began ideating on which items were important to sales teams, first with our internal sales team members:</p>
        <Box marginBottom={designTokens.space[4]} mdxType="Box">
          <ul>
            <li>Gamifaction of team members</li>
            <li>Trend data against timescales</li>
            <li>Indexing on revenue</li>
          </ul>
        </Box>
        <p>We created a new view for sales team members where they were able to view their clients' quotes, orders, and conversions while viewing the trend over time. They're also able to index on the company to view <strong>how much money they're quoting against how much they're purchasing</strong>.</p>
        <p>We also introduced a leaderboard so sales managers can track how well their sales teams are doing amongst each other.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Quickly scan how their entire company relative to a salesperson is doing</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72.5%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB5UlEQVQ4y42U63KjMAyF8/7PtzuddBtIQ5NAGsDGF65GeyQMSds/ZeZY8kUfsmzYHT5KSi41paKKkvOq8sn/nZi1E+eU0/6Q0T490+VuKK8cFbUTu+paWdifWsc5bgGi+ZuV9CfDWy4lvb0XdMoV4BdKPu50RNYpss0KLeOn4qsykaYMcwLkbZZK071W1PUDjeNE0xSoZ3+aKISZphAozPMPzVA/Buqg0o/0L4vAtm2paz2CJ1k0z0HsOI6bJsCHYfg6BvEYJ1Ib98jQWktN08gkg0JYgDzmnCNjDHnvZZ2DuG+sWeLgH4sa2d3kYHYpGg5USpHWWnzOmIEc2HoHCMQWcA/LIO+Wvsb6JK/oNfuMwJghBzOMfYbxwz7DnfMC4ixbt1gX+5xhioN5ANFYt7yVtW5727KPW45rPK8zZtu+hp/kNe1Pt6cMY514CytsBa61E8vbjC8WoFtqmFyrb0D7API14YeBXFfOUEqBYK6XjaVhEMco+K/nO73g/m5AOUlMdKjXJFdiEOh6CD7aYejhW+q7TrJrcdUYmmLLDyCaSltc7gaXu6HPSou1vicDNbYl4zrSbNFXDTKOfZ6r0M9rS9lNrd9yScdrven9qsRy5s86xp/HG07zgE9Sfgiwy9wiBv4HGqEqdhJ5eFEAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shipping receipt",
          "title": "Shipping receipt",
          "src": "/static/aa933290453d9012b261c18bd1f2c605/d9199/ca-sales-insights.png",
          "srcSet": ["/static/aa933290453d9012b261c18bd1f2c605/8ff5a/ca-sales-insights.png 240w", "/static/aa933290453d9012b261c18bd1f2c605/e85cb/ca-sales-insights.png 480w", "/static/aa933290453d9012b261c18bd1f2c605/d9199/ca-sales-insights.png 960w", "/static/aa933290453d9012b261c18bd1f2c605/07a9c/ca-sales-insights.png 1440w", "/static/aa933290453d9012b261c18bd1f2c605/29114/ca-sales-insights.png 1920w", "/static/aa933290453d9012b261c18bd1f2c605/97755/ca-sales-insights.png 2536w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <p>When thinking about gamifying the insight data, we had to reason out use cases from our users' point of view:</p>
        <Box bg={({
              theme
            }) => theme.greenTransparent} marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}><strong>Admins, Owner, CEO:</strong> want to view overall performance and health of the entire business</p>
        </Box>
        <Box bg={({
              theme
            }) => theme.pinkTransparent} marginBottom={designTokens.space[3]} mdxType="Box">
            <p style={{
                marginBottom: '0'
              }}><strong>Team Leader, Sales Leader: </strong> want to see the performance of the local business and each team member</p>
        </Box>
        <Box bg={({
              theme
            }) => theme.blueTransparent} marginBottom={designTokens.space[3]} mdxType="Box">
            <p style={{
                marginBottom: '0'
              }}><strong>Salesperson:</strong> want to see upcoming tasks assigned to them + how they rank amongst their team.</p>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">A leaderboard of their local company or team, ranked based on a sales metric</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAABaElEQVQoz22TCY7DIAxFe/9zTpe0UsjGvnj+JyFDO0WyYoP9sI1zCcNd0vMh6TVUicMDchfux07CNx1xFMSX/Kxx5RIAsXqTeVKyTJPkGKWk9F9yPnU59OCdBGfFGlteLotW0w4M3ouDWOeEq5QikYHUZV+0M0E8w6VcIYSqp5yLjkn8PAP4HCTD2QPYHPldlqXuNXtC9gl+xph6xkWbUN7ikEyY1B/QWivuyLABeuA4jhWgtX4Hwge8onGR74EGwHmez5Kop6Ns2kqpavPidV1PYL2UQOyfwOZI6TPsMyaQX5b8CdwztB8Zdo4MpM5sW2Dfw28ZbixZje89/HyE2nAGdo/yreTSgDVDjA3HwVpXG94c2fjWAowFgHtPOVrtUTAtbbwK94+SMeGcQcAodYRwuK0L9Fiz40vO6CEH2RotK4D8AWLw1TeFUMxZ8uMm7n4VD+HX3X52G/v20Jv9pt+upw0px375BZx4pRiyDMvVAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Shipping receipt",
          "title": "Shipping receipt",
          "src": "/static/12bb5a4e3010abb98cacbf7d50ea6390/d9199/ca-leaderboard.png",
          "srcSet": ["/static/12bb5a4e3010abb98cacbf7d50ea6390/8ff5a/ca-leaderboard.png 240w", "/static/12bb5a4e3010abb98cacbf7d50ea6390/e85cb/ca-leaderboard.png 480w", "/static/12bb5a4e3010abb98cacbf7d50ea6390/d9199/ca-leaderboard.png 960w", "/static/12bb5a4e3010abb98cacbf7d50ea6390/07a9c/ca-leaderboard.png 1440w", "/static/12bb5a4e3010abb98cacbf7d50ea6390/29114/ca-leaderboard.png 1920w", "/static/12bb5a4e3010abb98cacbf7d50ea6390/97755/ca-leaderboard.png 2536w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Product Analytics 💡</h5>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            <p style={{
                  marginBottom: '0'
                }}>How can users gauge consumer preferences using accumulated order data?</p>
          </CardBody>
        </CardContainer>
        <p>We wanted to level the playing field and give our contractors a way to see which options are selling vs. which options are just being quoted. We began testing a feature with our internal teams, with the reasoning of shaping our internal manufacturing shipments.</p>
        <p>After testing and slight polishes to how users can index upon options and timescales, we began introducing an Analytics feature to empower admins at contracting companies.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Analytics dashboard using bubble charts to show door options</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "53.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsTAAALEwEAmpwYAAACBklEQVQoz61R227aQBD1L4QQjI3vNrZ3DQgbQ4DUMZeEJqUpUUJUKagS6nv7UrVSn/od/aJ+1unsukRUfe3D0ezOzp45c0apNWzUm95/wanqQqlrHgbTG4zLO4yKNTqDOZJ0BtafIckWSAbLKmZzMMrzdA5OZ57OXvIi18mX8Nk5lIYeYDLboLzeYrp4xOXtMwrCxdUW5fIeo+lrjIu3uBTv83sUywcUV48k4J08T8qNPJerJ3RJjCJG5t0hsvwVnKALzYxguBwRH6Bb3CEuH2AnEzgeR5zkcKnG8TuwvQS6GVYw2mg5HJrNKkLWyZENCyruQGsF0C0iNQIM9z+w+fkL/HoHVbWoUUINQzSpRiOSYwhSVfegnKoOXOroeQwtIhIfRAzjPtosQ5QMYTkxTIfBDxIEBMOqVB0rlM2cRCi0YBFhfLGmkXuyWJA6XvU5zEpYYQq95SMYLMDIBifOoInmQh3VSoV0b1JUTs5MWETU235CfPMBQVrKzw4fIRit0N99RTC+RbNhIFp/BNt9R7LeyyYa1ekHQoqq7lcemqQqymn1T5/RPl9Ba5jgq2f0dt8QTtdyAcIGN+yDvdmDv/9CFvAXogNU8lYSigebxnZZLr1oUqcWRZ9GdKNUeiTGMcw2/C5tfLj6ZymaXMofhRUs1Gj82plV3UWsH90PoNxJTf87d4TfQQ5EsH4kZLUAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Product Analytics",
          "title": "Product Analytics",
          "src": "/static/2eee6d9e93b1a879bbf99504388bf528/d9199/ca-analytics.png",
          "srcSet": ["/static/2eee6d9e93b1a879bbf99504388bf528/8ff5a/ca-analytics.png 240w", "/static/2eee6d9e93b1a879bbf99504388bf528/e85cb/ca-analytics.png 480w", "/static/2eee6d9e93b1a879bbf99504388bf528/d9199/ca-analytics.png 960w", "/static/2eee6d9e93b1a879bbf99504388bf528/07a9c/ca-analytics.png 1440w", "/static/2eee6d9e93b1a879bbf99504388bf528/29114/ca-analytics.png 1920w", "/static/2eee6d9e93b1a879bbf99504388bf528/a1ccc/ca-analytics.png 2776w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Contractor-Homeowner interaction 🏠</h5>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            <p style={{
                  marginBottom: '0'
                }}>How can contractors interact with homeowners?</p>
          </CardBody>
        </CardContainer>
        <p>One of our goals was to <strong>help bridge the gap between contractors and homeowners</strong>. To do so, we needed to add marketing outreach and sales tools to our platform. We needed to find a way to empower the contractor to interact asynchronously with their clients without having to meddle in the middle.</p>
        <p>We began by viewing contractors' sales process and analyzing our interview data. We mapped out a high-level flow of our proposed experience and subsequntly began mocking out what features we needed to accomodate for the contractor and homeowner.</p>
        <p>To help bridge the gap we wanted the Advisar platform to:</p>
        <Box marginBottom={designTokens.space[4]} mdxType="Box">
          <ul>
            <li>Streamline door building/buying process</li>
            <li>Build more meaningful relationships with our consumers</li>
            <li>Bridge the gap between contractors and consumers</li>
            <li>Enable sales teams to interact with their consumers</li>
          </ul>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">High-level interaction flow between internal teams, contractors, and homeowners</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.083333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAACE4AAAhOAFFljFgAAACY0lEQVQoz2WT2W8SURTGq7EtIAPMxuzLHaYswwwtlEWU1AZoK1VsE1FTG8TGuj/oi/HZB2Pik/Ef/jwzbYyEhy8nM3fud35nmTWh6CKWLDvwRAdlkiE5yTteYRD0LeTLHRRKLfBaCZpSgkhnilGByXaSKF57xFpLLpKOcjouCiYWgoM5pyMq6BDdCDcnl9h48hnrpBvjORw9gGkwBEGAVquNKAph2d6yYayQzPq8iXtE183rcEQLPGXPNMfgOhNku8fINPahq1tQNQaHbaFSa4CVKlANb5WwLdoYU7kjMhxS9AQLBbOKzJ0psnszZPefId1+AEnzIcjxZQ8KnfMytaXIVg2HRPVS8fBC9XEm2KjTs+SEWB+dI3X8GqmHl7i19xSyXiZDky6b0CyfDHV6tlZLjlWkTNq/bA4NwQdX64MLB+Aa95Gr9CArLiR1G6p9AIMdQrHGlKSdfL9kGFNKJJUMCzRdUbLBE2F69gXc2Vdw59+Qnr6HaFPPvBmizm80er9If2B4cyI1ktKXCGVSTBibi7RGgu4jH/SRqw+QI8J8lQhVj/oYEw6huwcUR0S4u0q4ZBgTyjYKdh3rE+rf9B02H3/Axmh+TXiC+u4PhJ3vCLs/ifD5KmFCRRNWJRf5eIpEyBvlZF1y/WmibOuQ6BhkbQDLfQXHW8BmFyhqI/Kwlg1tVqZljRCSKrUQiuZe7SGtTaK7J7jdndAf5UOJHLBTH960CnbiQ+1QuQQiKP+VzEplNJtNNFstNLapR5qTlLz56C3SVG769BNSRwtIMi12j6H2cRvBmx0EFPVhCbx4ZfgXhflUdBDFg/YAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Contractor-Homeowner experience",
          "title": "Contractor-Homeowner experience",
          "src": "/static/6297b3062261add648c220a9807743f9/d9199/ca-homeowner-flow.png",
          "srcSet": ["/static/6297b3062261add648c220a9807743f9/8ff5a/ca-homeowner-flow.png 240w", "/static/6297b3062261add648c220a9807743f9/e85cb/ca-homeowner-flow.png 480w", "/static/6297b3062261add648c220a9807743f9/d9199/ca-homeowner-flow.png 960w", "/static/6297b3062261add648c220a9807743f9/07a9c/ca-homeowner-flow.png 1440w", "/static/6297b3062261add648c220a9807743f9/29114/ca-homeowner-flow.png 1920w", "/static/6297b3062261add648c220a9807743f9/b8765/ca-homeowner-flow.png 2868w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">Contractors can send homeowners a url to the door configurator so they can design their door</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABCcAAAQnAEmzTo0AAACjklEQVQ4y6WSzU8TQRjG+095FQoiEE8evPgHeDGkgX7tzM5+taUt4Ee8osaEtEWNMfHqwYsHDkoltkAhKZEYUdwu0KC0acM8vjNbCNGDMW7yyzs78+aZZ+aZyKdOD19OodkjWggJ/g3pU93t9mWk2f6BleoqVtbW8PbdKp48fYHHlefnPBpwcXxxTs+Xn8nSy1doBEcyUt/3YQlBmGCGgbGr1zAUHdcMj0xg5MrkH0RHJ2ltXBMdncDl4TF5/cZNVD/vycj6fguu58GyLHhUheMhxS3EU1zDhA3DtMAGGFyAWw6cTI7IwsvOwnYzMjd/B+vfg1DQdhxwzuG6LhhjEOR4cXER5XIZS0tLKJVKWF5eRqVS0ag1TqdxHRdxlkDKSMp8YQ61b746cguC3CkR5dQ0Tdj032g00O/3cXx8rKv6TqXUtdlsIplMION6iLEYEiwuC3MLoaBy6JEzi4Qynos040gbHB9rNfR6PXQ6HXS7Xfi+j3a7rQU3abOp2DQMZiKWjmM6lZCZ2QIoDxmpfm0hOmXh0i2GodsCabqjNDXW6nWcnJxoEVWVyzOnm40tzCRS+k65aSsD0s3lSbAlI2QTcQphhgmkLBemZYMLC1vb29qZ4vdvd6eJRCoNYTswbZuETZnNF0OHOmXHhi3CIytBZgp8oHep7i8IAhweHuKAODoI8Hr3CA/fvIdgBqVth/3clJl8IXSo3iGn+9Mpq1AGTRYlaNOlhwzGNJekUySFQ0GGfcolGZDZQvFcUNKzkfS4JaUr1W4Kg3FJAel6hvqnjQlaH/QpsbTBpJebRV2lvOEHWLh7D/liEYW5eai70BT+woU+jwKZv/8gfNg7P7tygwbr/0VLbvgHUmn9AjhNOy9gio2xAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Send invite",
          "title": "Send invite",
          "src": "/static/04abcf599557c231215445fbe9d8b3db/d9199/ca-leads-invite.png",
          "srcSet": ["/static/04abcf599557c231215445fbe9d8b3db/8ff5a/ca-leads-invite.png 240w", "/static/04abcf599557c231215445fbe9d8b3db/e85cb/ca-leads-invite.png 480w", "/static/04abcf599557c231215445fbe9d8b3db/d9199/ca-leads-invite.png 960w", "/static/04abcf599557c231215445fbe9d8b3db/07a9c/ca-leads-invite.png 1440w", "/static/04abcf599557c231215445fbe9d8b3db/29114/ca-leads-invite.png 1920w", "/static/04abcf599557c231215445fbe9d8b3db/f7cfd/ca-leads-invite.png 2391w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <p>Once a contractor sends an invite to a homeowner, a lead is created and the homeowner has a timed url that allows them to design a door through our <StyledLink to={'/work/masonite/door-configurator'} underline="true" mdxType="StyledLink">door configurator</StyledLink>.</p>
        <p>Homeowners can add/edit doors in their cart, which a contractor can view in realtime through the lead that was created.</p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <Text center marginBottom={designTokens.space[3]} size={designTokens.fontSizes[0]} color={({
      theme
    }) => theme.grey500} mdxType="Text">A view of the homeowner's cart</Text>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "67.91666666666667%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAACgElEQVQ4y32TW08aURSF+f8vTbUKJgg+961NSFpjbCyggkUZKSiiQJniBR2d+5kLs1f3nhmtaZtOsrLOOcP52LcpOMYZlHlBzsMALq+X3hhI5rjo76NcLmOzUmFtolqtopJLzsp8lot4T5t8Ph0dGQXt6BPazY/UOayhVf+AwBoBSx1z/QxfD7vYb2vYPeji/fYBatt11Haa2Kl/w26jgy+sRqtLzZZGjfYpbvSBUbieHmHU36PJsCmOyL0CgilitQAhe8TDhF72fzyUC4E7NwoIZ0CsEyIdsl56EyT+GJ41h+WGsLwAropgWTZs28Hi0Ybx5MB2LViODT9YkqtC8sKE78wYiFtm31LurLvMyfxnOMdPMbqGAsUxgjCGH8bkBTGpiDJgsbSBd+slYmGNlXqxhJW1DaysV1AsVfGWfbW4hTerZWi9szQ/VwlsCYH5r4En30fQ+iPq9Eb43B6g3tKwt3+Cw+M+GscD1Fp9nPSG/JsL9nPo1wuoiNMLIoGlQF7/Bi6zipL8q2laUJ73kp7PatlZxZO88l7AUYUEP+L3kTiRiANmoG4UHC6444fE79FtNDDodOApBds0EXNnFypNK22MwAzjFo/3Uzw+6KyZiERPxk8oa2wUnsMW4FA7xeR8iDDiy66bAu+DBCqrVRaZMwd5o3QSEv9KRCIo/iDU9BWQ8xme9jBmoB/wqDAwiBPM3LSTfCZAhrs8AcGExeMW/BBRKhk/AUoqaevZOQAeYKSXpYPiURjnxZeUBXjDFycghpGaikgkQBIglw9cUO4SgyQl2fP6Wf7Lmt9x4ZXLc8rpPQPT6HJgGuHi5hIsyv2/uru+hGdepanm0f0F/AVV8wusWXFd+gAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Empty State Onboarding",
          "title": "Empty State Onboarding",
          "src": "/static/a341f56ad0f416281d1d641ea1342c87/d9199/ca-consumer-onboarding.png",
          "srcSet": ["/static/a341f56ad0f416281d1d641ea1342c87/8ff5a/ca-consumer-onboarding.png 240w", "/static/a341f56ad0f416281d1d641ea1342c87/e85cb/ca-consumer-onboarding.png 480w", "/static/a341f56ad0f416281d1d641ea1342c87/d9199/ca-consumer-onboarding.png 960w", "/static/a341f56ad0f416281d1d641ea1342c87/07a9c/ca-consumer-onboarding.png 1440w", "/static/a341f56ad0f416281d1d641ea1342c87/29114/ca-consumer-onboarding.png 1920w", "/static/a341f56ad0f416281d1d641ea1342c87/c6a7a/ca-consumer-onboarding.png 2719w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "101.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADVklEQVQ4y4WUy09TQRTG+W905aK2lL57C21pKX1CH9ACLQF5KUoE1ESeLjAg4obXRoyJSiJhZyCRPQkbViS4EYkSyqu8HwXmc2bC3BRQuMnJue2c87vfmXNmsk5OTnB8fAzmxfvZ2Rlueth6Zg6zdDrNfda/YKurq5iamsLk5CS+fP6ED2NjGB4ewsDAOwwODSOZTMpQBhIw5mUg80dHRyDn52hr74TG6IDR4oRKrUe2xgSFSgeLzQeVLh9fJya5UhZ/TaGACTs9TaPp6TO43VFEYnVw+SvgdEXg9EQRjTfA5izG+7GPl4DXFApjAezp6n4FtS6PJ1tsXlij9cgtCCHPHoBKk4shWvZVoLBLwMPDQx7Y19cHjSEfvlA13IE4gl2j8MebECythWQvQm/vGx4ntusSMLNTAjg6MkKBNsQSjSgpr0dxKIFwtBqlFXWQrB50dnb/HyjUsYWDC+D4+DhUOQY4XAFuTncRjJIdJmoqtREv2zpuVigWxB5OT08jR2tCbX0TfEUl8PhC3NudXvohE1pan99eMvvBAtgzOzsLnUFCy4tOFIfLqMXg9gZpUwr5CD1qfHIBPLkOzBxMAVxYWIDOKKG1vhmxaCX8wVI4CgPIy3dDrZVQVV3L4zL3Xx4bAcsELi//glmy0pIfI56ogddTDJ87AJfLz4Fl5Qmc0wNwFSgPduZwssDNzU3Y7AWIP6hBLF6NgnACjngjbP4ocjRmhEtiF/uXvr1k5re2thCORKG8r4XeEYA0+A3S6HeY6Cxqs3UIBEuQSm3JhyHzPpC7zGZwf38fe3t7FLiJRGUV1LQBJks+DGYbDDozDAYL7b4Zbk8Af/785jkHBwccKsBZ7EXAdnZ2eLkM2PCwEQqlFgaTlXY8F3pjHgdr9RZ6tr34sbgoC2D5MlB8hS2kUimsra1R6AaaW1qhzNZDynVwqJZCc2hDFEodHXArnwQh5CqQUCChC4QqJOvr64SCSXt7B7lz9x5RKDWEXmFEstiIq9BHgqEI6e9/SzY2NggVwXMZgwK5ySULpbu7u9zm5ubQ0/MaIyOjmJiYwMzMDObn57G09JNfsKwSVhGLZXlCJTvLMl2oZUq3t7e5UqaEqV5NJsnKygo3eqPz/9k6BfLqhMq/4ikH0AWBDR0AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Consumer Mobile Empty",
          "title": "Consumer Mobile Empty",
          "src": "/static/7f2826906813e0ce0c995ce6c43c49e8/d9199/ca-mobile-consumer.png",
          "srcSet": ["/static/7f2826906813e0ce0c995ce6c43c49e8/8ff5a/ca-mobile-consumer.png 240w", "/static/7f2826906813e0ce0c995ce6c43c49e8/e85cb/ca-mobile-consumer.png 480w", "/static/7f2826906813e0ce0c995ce6c43c49e8/d9199/ca-mobile-consumer.png 960w", "/static/7f2826906813e0ce0c995ce6c43c49e8/07a9c/ca-mobile-consumer.png 1440w", "/static/7f2826906813e0ce0c995ce6c43c49e8/29114/ca-mobile-consumer.png 1920w", "/static/7f2826906813e0ce0c995ce6c43c49e8/5e703/ca-mobile-consumer.png 1942w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.25%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABCcAAAQnAEmzTo0AAAB1UlEQVQ4y6WSTW/TQBiE/b+5gChqoG2oVIkmVQuorcM3CDhw65VLQRxSCCDkphFpooKc2F6v1/sxjDdpSYFKEbX0+PV6Z8fv7DpA1gVwQn5cBguMQa8oEMMOHrd2EO7untEiD0PWMMSDilbI58n78B/sbG/b1y9fIBt2omB8/Alr6/dxa3UTtXoTN29v4PpKE1dqd3BtcQ1Xa4R1YWkdC8sNLNUbWCGL1NbqG15/Y7lhm1shRv2PUeDSHv68cvJNAEppJLJESux0rlcCX+RfS/y0y3pRYMZdpEIiyRUSUfiacZylKQoaGmthrYMq6aRLvPme49FhhhG16e81VmkDk3APeYMoSghlWDUyihy/9uruJvaePoGm2XAwQByP2IeB4vhDrJFP9R5lrHGOhkfnDfNqkvFKOrbfvcfn9gEshVW3Uk5yFsYhVhaynBjmcxly4cH+Pr6224xMwyyDEIINahQc93MDqeYwPBf53hb2nj/zkQfHfUaOq2333b39WfrI+TyRz/amqqRgNKWtr5KHVJYaMNSqyszMEXmK72B242dITzUXdOg44XjyxEzr6fPs+CIqjaMhJoYuO4IsCqL+GyELOOf/w27gkijX48PLIsw4svTq/AKLdRErPZLzlAAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Homeowner cart",
          "title": "Homeowner cart",
          "src": "/static/baceec658b2e51275c46327e435d87a6/d9199/ca-homeowner-cart.png",
          "srcSet": ["/static/baceec658b2e51275c46327e435d87a6/8ff5a/ca-homeowner-cart.png 240w", "/static/baceec658b2e51275c46327e435d87a6/e85cb/ca-homeowner-cart.png 480w", "/static/baceec658b2e51275c46327e435d87a6/d9199/ca-homeowner-cart.png 960w", "/static/baceec658b2e51275c46327e435d87a6/07a9c/ca-homeowner-cart.png 1440w", "/static/baceec658b2e51275c46327e435d87a6/29114/ca-homeowner-cart.png 1920w", "/static/baceec658b2e51275c46327e435d87a6/4654d/ca-homeowner-cart.png 2392w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Sales Insights ✨</h5>
        <CardContainer mdxType="CardContainer">
          <CardBody mdxType="CardBody">
            <p style={{
                  marginBottom: '0'
                }}>How can contractors find sales prospects based on data in their sales regions?</p>
          </CardBody>
        </CardContainer>
        <p>We wanted to empower contractors to find sales more easily. By using public property data and overlaying their chosen sales zip codes, we help filter where sales may occur:</p>
        <Box marginBottom={designTokens.space[4]} mdxType="Box">
          <ul>
            <li>Recently sold homes</li>
            <li>Open and registered contruction permits</li>
            <li>Median zip code income</li>
          </ul>
        </Box>
        <p>
        By using multivariate data, contractors can better target sales prospects - in turn, increasing conversion, reducing inefficiencies, and growing their business.
        </p>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "68.75%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADkElEQVQ4y22U60/bVRzGm5jNwMZllN6BAW3phVKgQCm3lA0ZUCZLNWuC4BKWbV6mWTQaXywRcYkwxwAR2NgWdYagyzT6Qv8Sd/EyGQwodKWUUlrufjz9MffKkzz55vc75zx5zvOc75E93djlyUqM2Uic2ZUEYs8xHYryeGmVxyGBpQhTwYhU/8NUooq56eW9Nf61TWQJImdtI2qtnvyCYpQ6gwSF1kCu0U5BYTlGSxkmWwUWuwujzYneWi6+nZjtToxmMVdUjUXMf/X9T8j+XAzxiq+Dnku9DHwxyuX+IXo/H+DLsXG8J9tJTpEjV2hRmR3k1TSTX16Pra4Zvfs4xUUuKopMHGmspNRg4vbkXWS/TfsZHB7j/8ZnfVfYn5SOWpeHKqcAlcONvb4Vt/c0luMdODyt1LTX8dElN4U15XybUHhvZoHLV4eIx2OEQiGi0SixWFwi7O7+lKTUTDRZ+Sg1h8lUZiHPs2Bu8WHv7ODoWS8tH7zMqZ4T6Jxubt35WSic8dPXP8h6PE44HGZ9fZ2drU2J8MLFHvbvS5bIVLp8tDlGVKpsNPYyfO+8xLtv1dH25lGKO0+QYqnhxo+/PiO8MsjGxiYrKxG2trbZ2d6WCD8cGOegIgeVNleCRhAqlNmkZOXy8dkS3uss5VCBFaPnGCmF1Yzf/QXZg9lFekUQ/sUAC4EgwVCYwNIyOzu7dA/dIMPVhKG6UQSjk8JRCoU6i51zp2vxCnUqQagT/iar87n2nfDwwVyAkeu3iK9vEFmNshpdYzkckRR+0jcsjG+g5vxJclzNKMTGREBqvRVDm4e8phbkaqFc/Es6kM7o1xPI7guFg8OjbG1usrYWZfefXUhAjPPdVylqO0LzBQ/ZJS40uSLpbD1qezVZVR6yKo+hyLOiEh6/mPyM8N6TBfqHRsRxl5iZDTA3HxDVLxEO9g9T4bThO1OBwW4WRzNISSuEn2qLA22ZG7W1QjRFLkkHM/YI//AHee3UGUbGrnNzcpyRiZsMi0s9MXmHrq5zmEtKuPiGjdZ2E6mZ2aQdUklITVeQlqEhTa4lI1PLC/sOMPrNxF7rVdY2YLIW8WpXLVWv19Hk81LmrMVZXU+Vp4W332+kwVOF3lyK2VYq2i4BB6bCUgkWcY0O6y3c/kHcw8Tj8Jdov4cinKnFIH8HgzwKhnm0uExCvQRhx31hx+9izcP5vSph/ulzJPbPCXH/AgJzq2Im+iL/AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Sales Insights",
          "title": "Sales Insights",
          "src": "/static/2186e173daa66ed27ffe723390bbb39a/d9199/ca-sales-regions.png",
          "srcSet": ["/static/2186e173daa66ed27ffe723390bbb39a/8ff5a/ca-sales-regions.png 240w", "/static/2186e173daa66ed27ffe723390bbb39a/e85cb/ca-sales-regions.png 480w", "/static/2186e173daa66ed27ffe723390bbb39a/d9199/ca-sales-regions.png 960w", "/static/2186e173daa66ed27ffe723390bbb39a/07a9c/ca-sales-regions.png 1440w", "/static/2186e173daa66ed27ffe723390bbb39a/29114/ca-sales-regions.png 1920w", "/static/2186e173daa66ed27ffe723390bbb39a/9b204/ca-sales-regions.png 2520w"],
          "sizes": "(max-width: 960px) 100vw, 960px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <br />
    <Section mdxType="Section">
  <Container mdxType="Container">
    <CSSGrid mdxType="CSSGrid">
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
      <Col col={6 / 8} media={{
            phone: 1
          }} mdxType="Col">
        <h5>Next Steps and Takeaways 💡</h5>
        <p>First off, if you've made it this far, thank you 🙏 - this project is a labor of love for our amazing team and I'm happy I can share a bit of our process!</p>
        <Box bg={({
              theme
            }) => theme.grey200} color={({
              theme
            }) => theme.grey900} marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}><strong>Be curious and proactive:</strong> we took a user-first approach with the Advisar platform by reaching out to people to understand more about their processes. Whether it was stakeholders, contractors, customer support - each have provided data that helped us build a scalable, profitable platform.</p>
        </Box>
        <Box bg={({
              theme
            }) => theme.grey200} color={({
              theme
            }) => theme.grey900} marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}><strong>Intention and push back:</strong> As a team, we learned to make products with reasoning whether that is UX or business related. I also learned that it's okay to push back if I need more time. It’s okay to push ideas if you are passionate about driving a better experience.</p>
        </Box>
        <Box bg={({
              theme
            }) => theme.grey200} color={({
              theme
            }) => theme.grey900} marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}><strong>Bringing value to the larger business:</strong> One of the things we all had to keep in mind was how the platform for this side of the business would integrate and scale with the larger parent, Masonite. Figuring out those overlaps could prove beneficial - where we could consolidate our digital products instead of having separate products for the same issues in different sides of the larger business.</p>
        </Box>
        <Box bg={({
              theme
            }) => theme.grey200} color={({
              theme
            }) => theme.grey900} marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}><strong>Measure outcomes not output:</strong> It seems as though traditional development teams have a bias for measuring on quantitative output, rather than how successful a product lands with a user group. However, this project has taught us that the speed of the build should be less important than "what we build" and "why we are making it".</p>
        </Box>
        <Box bg={({
              theme
            }) => theme.grey200} color={({
              theme
            }) => theme.grey900} marginBottom={designTokens.space[3]} mdxType="Box">
          <p style={{
                marginBottom: '0'
              }}><strong>From invoicing to consulting:</strong> One of the largest pivots we've made is to transition from a simple quoting tool to a platform that can empower a contractor and help them grow their business. We're still trying to figure out what a contractor needs and how they would use these tools, but we think we're on the right track.</p>
        </Box>
      </Col>
      <Col col={1 / 8} media={{
            phone: 1
          }} mdxType="Col"></Col>
    </CSSGrid>
  </Container>
    </Section>
    <CallToAction mdxType="CallToAction" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      